import * as React from "react";
import { Button } from "react-daisyui";

import iconImg from "../images/tripadvisor-img.png";

import { Link } from "gatsby";

const HeroSection = () => {
  return (
    <div className=" w-full h-full bg-[url('../images/bg-main.jpg')] bg-no-repeat bg-cover">
      <div className="bg-black bg-no-repeat bg-cover bg-opacity-30">
        <div className=" w-full h-screen flex flex-col justify-between">
          <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
            <div className="flex flex-col justify-center md:items-start w-full px-2 md:ml-10 py-2 ">
              <h1 className="py-3 text-4xl  md:text-6xl font-bold text-white font-font1">
                Sarawak-Inspired Cooking Class
              </h1>
              <p className="py-3 text-xl  md:text-[24px] font-bold text-white font-font1">
                by Award Winning Indah since 2019
              </p>
              <p className=" text-[16px] md:text-[20px] text-white font-font1">
                Go from zero to hero in the kitchen Now Everyone can cook!
              </p>
              <div className="w-6/12 py-5">
                <img src={iconImg} alt="" />
              </div>
              <div className="flex space-x-2 py-5">
                <Button
                  className="rounded-3xl text-white text-[14px]"
                  variant="outline"
                >
                  <Link to="/about-us">Learn More</Link>
                </Button>
                <Button
                  className="rounded-3xl text-white text-[14px]"
                  variant="outline"
                >
                  <a href="https://wa.link/rvolk6">Book a Class</a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
