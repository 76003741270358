import React from "react";

const PricingSection = () => {
  return (
    <div className="bg-white font-poppins ">
      <div className="container px-6 py-8 mx-auto">
        <h1 className="mt-4 text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl">
          Pricing
        </h1>
        <p className="mt-4 text-xl  text-center">
          If you want to reserve a session in our cooking class, contact us and
          we will attend you quickly, As soon as possible.
        </p>

        <div className="mt-6 space-y-8 xl:mt-12">
          <div className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl border-black">
            <div className="flex items-center">
              <div className="flex flex-col items-center mx-5 space-y-1">
                <h2 className="text-lg font-medium text-black sm:text-2xl ">
                  Cooking Class
                </h2>
              </div>
            </div>

            <h2 className="text-2xl font-semibold text-black sm:text-4xl ">
              RM198 <span class="text-base font-medium">/ Session</span>
            </h2>
          </div>

          <div className="flex justify-center">
            <button className=" font-poppins px-8 py-2 tracking-wide text-white capitalize transition-colors duration-200 transform bg-gray-800 rounded-md hover:bg-red-600 hover:text-white ">
              Book Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
