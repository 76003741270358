import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MainSection from "../components/MainSection";
import ExperienceSection from "../components/ExperienceSection";
import MenuSection from "../components/MenuSection";
import PricingSection from "../components/PricingSection";
import NewsletterSection from "../components/NewsletterSection";

const index = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <MainSection />
      <ExperienceSection />
      <MenuSection />
      <PricingSection />
      <NewsletterSection />
    </div>
  );
};

export default index;
