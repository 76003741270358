import React from "react";

const MainSection = () => {
  return (
    <div className="w-full my-32 ">
      <div className="max-w-[1240px] mx-auto">
        <div className="text-center px-4">
          <div className="py-5">
            <h3 className="text-4xl  text-black text-center font-poppins">
              Welcome to Indah Cafe’s Cooking Class Studio
            </h3>
            <h4 className="py-2 italic text-2xl">
              Fun + laughter in the kitchen every single day
            </h4>
          </div>
          <p className="text-xl">
            Indah Cafe offers hands-on cooking and baking classes in a relaxed
            and fun environment. Get to know your ingredients, explore Sarawak
            gastronomy and leave with new skills and happy memories.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
