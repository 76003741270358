import React from "react";

import cookLocal from "../images/cook-local.jpg";
import eatLocal from "../images/eat-local.jpg";
import shopLocal from "../images/shop-local.jpg";

const ExperienceSection = () => {
  return (
    <div className="w-full my-32 font-poppins bg-white">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="text-center">
          <p className="text-4xl font-font2 font-semibold text-black inline border-b-4 border-black ">
            Experience
          </p>
          <p className="py-4 md:text-[20px]">
            Put aside half a day for the following Indah experience - Served
            with cultural insights and good fun.
          </p>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-28 text-center py-16 ">
          <div className="px-2">
            <div className="shadow-md shadow-[#040c16]  md:scale-[1.3] hover:scale-[1.5] duration-500">
              <img className="mx-auto" src={shopLocal} alt="" />
            </div>
            <div>
              <p className="my-10 font-semibold text-xl md:text-[26px]">
                Shop Local
              </p>
              <p>
                Visit a local market for fresh ingredients - Learn about
                Sarawak’s unique jungle produce, herbs and spices. Our market is
                vibrant, colourful & full of surprises.
              </p>
            </div>
          </div>

          <div className="px-2">
            <div className="shadow-md shadow-[#040c16]   md:scale-[1.3] hover:scale-[1.5] duration-500">
              <img className="mx-auto" src={cookLocal} alt="" />
            </div>
            <div>
              <p className="my-10 font-semibold text-xl md:text-[26px]">
                Cook Local
              </p>
              <p>
                Prepare a 5-course meal. Unique dishes with different flavour
                profiles.
              </p>
            </div>
          </div>
          <div className="px-2">
            <div className="shadow-md shadow-[#040c16]   md:scale-[1.3] hover:scale-[1.5] duration-500">
              <img className="mx-auto" src={eatLocal} alt="" />
            </div>
            <div>
              <p className="my-10 font-semibold text-xl md:text-[26px]">
                Eat Local
              </p>
              <p>
                Sit and enjoy your meal. Detailed recipes will be provided.
                Leave with a happy belly and memories built to last.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSection;
