import React from "react";
import regularMenu from "../images/regular-menu.jpg";
import veganMenu from "../images/vegan-menu.jpg";
import { Link } from "gatsby";

const MenuSection = () => {
  return (
    <div className="w-full ">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="text-center">
          <p className="text-4xl font-semibold text-black inline border-b-4 border-black ">
            Menu
          </p>
        </div>

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10 text-center py-16 ">
          <div className="px-2">
            <div className="shadow-md shadow-[#040c16]  hover:scale-[1.1] duration-500">
              <Link to="/regular-menu/">
                <img className="mx-auto" src={regularMenu} alt="" />
              </Link>
            </div>
            <div>
              <p className="my-4 font-semibold text-xl md:text-2xl font-font1">
                Regular Menu
              </p>
            </div>
          </div>

          <div className="px-2">
            <div className="shadow-md shadow-[#040c16]  hover:scale-[1.1] duration-500">
              <Link to="/vegan-menu/">
                <img className="mx-auto" src={veganMenu} alt="" />
              </Link>
            </div>
            <div>
              <p className="my-4 font-semibold text-xl md:text-2xl font-font1">
                Vegan Menu
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuSection;
